import * as React from "react";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// markup
const RaceSection = ({ location }) => {
  const title = "קטע מספר 1 – אנדרטת הפלדה – תלמי יוסף";
  return (
    <Layout path={location.pathname} title={title}>
      <SectionContent title={title}>
        <p>
          <strong>אורך הקטע: 5.91 ק&quot;מ</strong>
        </p>
        <p>
          <strong>רמת הקושי: קלה</strong>
        </p>
        <p>
          <strong>תיאור הקטע</strong>:
        </p>
        <p>
          יוצאים מהשביל המרכזי של האנדרטה לכוון צפון &#8211; מזרח עד למפגש עם
          גדר יישובי חבל שלום. רצים לאורך הגדר בשביל שדות בכיוון כללי דרום עד
          למפגש עם כביש הכניסה למושב יבול, כ-1.5 ק&quot;מ. חוצים את הכביש
          בזהירות ופונים שמאלה לכיוון מזרח. רצים לצד הכביש, חוצים את כביש
          ה'חלוציות' ממזרח לכיכר וממשיכים לצד כביש 2200 עד לצומת תלמי יוסף- פרי
          גן ו'דרך השדות' עד לתחנת תלמי יוסף.
        </p>
        <p>
          <strong>טופוגרפיה</strong>: מגמת עליה מתונה.
        </p>
        <p>
          <strong>מסלול</strong>: דרך שדות חולית, כביש, שביל חום ומדרכות.
        </p>
        <p>
          <strong>בטיחות</strong>: זהירות יתרה בחציית כביש החלוציות ובריצה לצד
          הכביש במקומות בהם לא קיים שביל.
        </p>
        <p>
          <strong>תחנת החלפה</strong> : בצומת תלמי יוסף &#8211; פרי גן ו'דרך
          השדות'
        </p>

        <div>
          <p>
            <iframe
              class="alltrails"
              src="https://www.alltrails.com/widget/map/sun-06-mar-2016-11-33?u=m"
              width="100%"
              height="400"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              title="קטע מספר 1 – אנדרטת הפלדה – תלמי יוסף"
            />
          </p>
        </div>
      </SectionContent>
    </Layout>
  );
};

export default RaceSection;
